<template>
      <div class="relative-pos grow-custom-1 flex-box flexcol ocy-white-8">
          <!--headerblok-->
          <div id="headpage" class="relative-pos w-100-ps-min ocy-white-8 shadow">
              <div class="flex-box flexcol w-100 padding-custom-05">
                  <div class="flex-box flexrow ai-center gap-05">
                      <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                      </div>
                      <div class="grow-custom-1">&nbsp;
                      </div>
                  </div>
              </div>
          </div>
          <!--endHeaderblok-->

          <!--bodyBlok-->
          <div id="bodypage" class="grow-custom-1 relative-pos w-100-ps-min flex-box flexrow jc-center padding-custom-1 ocy-white-3">
              <div class="relative-pos flex-box flexcol jc-start w-100 wmax-50">
                  <div class="w-100-ps flex-box flexcol ai-center jc-center padding-custom-1">
                        <img :src="myIconLogo" class="ocy-img-08-no-hover h-75-px" alt="icon-logo">
                        <span class="text-dark font-bold font1050rem font-uppercase letterspace25">
                            {{ title01 }}
                        </span>
                        <span class="text-secondary font10rem letterspace15">
                            {{ title02 }}
                        </span>
                  </div>  
                  <div class="w-100-ps flex-box flexcol ai-start padding-custom-2 ocy-white-6 rounded">
                        <div @click="goP1Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-info rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                Dual View
                            </div>
                        </div>
                        <div @click="goP2Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-info rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                Single View Smith Detection
                            </div>
                        </div>
                        <div @click="goP3Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-info rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                Single View Astrophysics
                            </div>
                        </div>
                        <div @click="goP6Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-info rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                Single View Gilardoni
                            </div>
                        </div>                        
                        <!--
                        <div @click="goP4Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-secondary rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                <div class="flex-box flexrow-col ai-start jc-start">
                                    <span>General Dual View&nbsp;</span>
                                    <span class="text-warning">[--Beta Version--]</span>
                                </div>
                            </div>
                        </div>
                        -->
                        <div @click="goP5Page" class="margin-bottom-2 handCursor w-100-ps padding-custom-05 flex-box flexrow bg-info rounded shadow">
                            <div class="padding-custom-05 flex-box flexrow ai-center jc-center font1025rem font-bold bg-light rounded">
                                <i class="bi bi-clipboard-check"></i>
                            </div>
                            <div class="grow-custom-1 padding-custom-05 text-white font-bold font10rem letterspace25 flex-box flexrow ai-center jc-start">
                                Mettler Toledo
                            </div>
                        </div>                        
                  </div>  
                  <div class="flex-box flexcol padding-custom-1">&nbsp;
                  </div>
              </div>
          </div>
          <!--endBodyBlok-->

          <!--footerBlok-->
          <div id="foterpage" class="relative-pos w-100-ps-min flex-box flexrow jc-center ocy-white-3">©{{ yearnow }} Fsm.Co.Id 
          </div>
          <!--endFooterBlok-->
      </div>
</template>

<script>

export default {
  name: 'App',
  components: 
  {
  },
  data() {
      return {
        myLogo : window.origin+'/img/FSM Delivering Right.png',
        myIconLogo : window.origin+'/img/logo01.png',
        myTxtLogo : window.origin+'/img/text_fsm.png',
        yearnow : '',
        title01 : 'Report Panel',
        title02 : 'Preventive Maintenance',
        // p1link : 'http://uipmr.test',
        // p2link : 'http://pm.test',
        // p3link : 'http://reportastro.test',
        // p1link : 'https://ui.fsm.co.id',
        p1link : 'https://rpscanndv01.fsm.co.id',
        p2link : 'https://pmr2front.fsm.co.id',
        p3link : 'https://pmr3front.fsm.co.id',
        p4link : 'https://pmr4front.fsm.co.id',
        p5link : 'https://mettler01front.fsm.co.id',
        p6link : 'https://pmr5front.fsm.co.id',
      }
  },
  created() {
  },
  mounted() {
      this.currentYear();
  },
  computed: {
  },        
  methods : {
        currentYear() {
            let tglnow = new Date()
            this.yearnow = tglnow.getFullYear()
        }, 
        loadedOnce(){
            localStorage.removeItem('reloaded');
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded');
            } else {
                localStorage.setItem('reloaded', '1');
                location.reload();
            }
        },
        currentDate() {
            let tglnow = new Date()
            let thn1 = tglnow.getFullYear()
            let bln1 = tglnow.getMonth()+1
            let tgl1 = tglnow.getDate()
            let bln2 = bln1
            let tgl2 = tgl1

            if (bln1 < 10)
            {
                bln2 = '0'+bln1
            }
            else
            {
                bln2 = bln1
            }
            
            if (tgl2 < 10)
            {
                tgl2 = '0'+tgl1
            }
            else
            {
                tgl2 = tgl1
            }
            
            let date = thn1+''+bln2+''+tgl2
            return date
        },      
        currentTime() {
            let timenow = new Date()
            let hour1 = timenow.getHours()
            let minute1 = timenow.getMinutes()
            let hour2 = hour1
            let minute2 = minute1

            if (hour1 < 10)
            {
                hour2 = '0'+hour1
            }
            else
            {
                hour2 = hour1
            }
            
            if (minute1 < 10)
            {
                minute2 = '0'+minute1
            }
            else
            {
                minute2 = minute1
            }
            
            let time = hour2+''+minute2
            return time
        },            
        goP1Page(){
            window.open(this.p1link, '_blank')
        },
        goP2Page(){
            window.open(this.p2link, '_blank')
        },
        goP3Page(){
            window.open(this.p3link, '_blank')
        },
        goP4Page(){
            window.open(this.p4link, '_blank')
        },
        goP5Page(){
            window.open(this.p5link, '_blank')
        },
        goP6Page(){
            window.open(this.p6link, '_blank')
        },        
  }        

}
</script>